<template>
    <nav class="custom-nav">
        <div class="nav-items">
          <router-link to="messages" class="messages">
            <img src="@/assets/icon/chat.png" alt="messages"/>
          </router-link>
          <router-link to="profile" class="profile">
            <img src="@/assets/icon/account.png" alt="profile"/>
          </router-link>
          <router-link to="friends" class="friends">
            <img src="@/assets/icon/people.png" alt="friends"/>
          </router-link>
        </div>
    </nav>
    <div class="container">
      <div class="block">
        <router-view />
      </div>
    </div>
</template>

<script setup>
</script>

<style scoped>
.custom-nav{
  position: fixed;
  top: auto;
  bottom: 0;

  background: #f7f7ff;
  width: 100%;

  display: flex;
  justify-content: center;
  padding-bottom: 3rem;
}

.nav-items{
  display: flex;
  justify-content: center;

  gap: 1rem;

  a{
    color: white;
    text-decoration: none;

    height: 3rem;
    width: 3rem;

    display: flex;
    justify-content: center;
    align-items: center;
    transition: all .5s;

    img{
      height: 1.75rem;
      width: 1.75rem;

      transition: all .5s;
    }
  }

  a.active{
    img{
      height: 2rem;
      width: 2rem;
    }
  }
}

a:hover{
  img{
    height: 2rem;
    width: 2rem;
  }
}
</style>