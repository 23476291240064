import { createApp } from 'vue'
import router from "../router/router";
import store from "../store/store";
import App from './App.vue'
import axios from "axios";
import { getCookie } from './cookie'

const app = createApp(App)

app.use(router)
app.use(store)

app.mount('#app')

// axios.defaults.baseURL = 'http://localhost:8000';
axios.defaults.baseURL = 'http://uniwo.ru/api';
axios.defaults.headers.common['Token'] = getCookie('auth_token');
axios.defaults.headers.post['Cache-Control'] = 'no-cache';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.withCredentials = true;
