<template>
  <div>
    <h1>PAGE NOT FOUND</h1>
  </div>
</template>

<script setup>
</script>

<style>
/* your style */
</style>