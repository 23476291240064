import { createStore } from 'vuex'

const store = createStore({
    state () {
        return {
            user:{},
        }
    }
})

export default store;