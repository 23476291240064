<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid justify-content-between d-flex">
      <a class="navbar-brand" href="#">UNIWO</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link to="/login" class="nav-link">Вход</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/register" class="nav-link">Регистрация</router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>

    <div class="container">
      <div class="block">
        <router-view />
      </div>
    </div>
</template>

<script setup>
</script>

<style>
</style>