import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "vuex";
import { getCookie } from "@/cookie";
import axios from "axios";

const router = createRouter({
    routes: [
        {
            path: '/profile',
            component: () => import('@/pages/ProfilePage.vue'),
            name:"Profile",
            meta: {
                layout: "DefaultLayout",
            },
        },
        {
            path: '/friends',
            component: () => import('@/pages/FriendsPage.vue'),
            name:"Friends",
            meta: {
                layout: "DefaultLayout",
            },
        },
        {
            path: '/messages',
            component: () => import('@/pages/MessagesPage.vue'),
            name:"Messages",
            meta: {
                layout: "DefaultLayout",
            },
        },
        {
            path: '/login',
            component: () => import('@/pages/AuthPage.vue'),
            name:"Login",
            meta: {
                layout: "AuthLayout",
            },
        },
        {
            path: '/register',
            component: () => import('@/pages/RegisterPage.vue'),
            name:"Register",
            meta: {
                layout: "AuthLayout",
            },
        },
        {
            path: '/admin',
            component: () => import('@/pages/ProfilePage.vue'),
            meta: {
                layout: "AdminLayout",
            },
        },
        {
            path: '/error',
            name: 'Error',
            // component: () => import('@/pages/Error') (Optional)
            meta: {
                layout: 'ErrorLayout' // name of the layout
            }
        }
    ],
    history: createWebHistory()
})
router.beforeEach(async (to) => {
    let userStore = JSON.parse(localStorage.getItem('user'))
    let store = useStore()
    let tokenStore = getCookie('auth_token')

    if (tokenStore && userStore) {
        let date = new Date(Date.now() + 86400e3).toUTCString();
        // secure
        document.cookie = 'auth_token=' + tokenStore + ';path=/;expires=' + date;

        store.state.user = userStore

        axios.defaults.headers.common['Token'] = tokenStore;
    }

    if ((!userStore || !tokenStore) && to.name !== 'Login' && to.name !== 'Register') {
        localStorage.clear()

        return { name: 'Login' }
    }

    if (tokenStore && userStore && (to.name === 'Login' || to.name === 'Register')) {
        return { name: 'Profile' }
    }
})
export default router;