<template>
  <div id="app">
    <AuthLayout v-if="route.meta.layout === 'AuthLayout'">
      <router-view class="content" />
    </AuthLayout>

    <AdminLayout v-if="route.meta.layout === 'AdminLayout'">
      <router-view class="content" />
    </AdminLayout>

    <ErrorLayout v-if="route.meta.layout === 'ErrorLayout'">
      <router-view class="content" />
    </ErrorLayout>

    <DefaultLayout v-if="route.meta.layout === 'DefaultLayout'">
      <router-view class="content" />
    </DefaultLayout>
  </div>
</template>

<script setup>
import AuthLayout from '@/layouts/AuthLayout.vue'
import DefaultLayout from '@/layouts/DefaultLayout.vue'
import ErrorLayout from "@/layouts/ErrorLayout.vue";
import AdminLayout from "@/layouts/AdminLayout.vue";
import { useRoute } from 'vue-router'

const route = useRoute()
</script>

<style>
#app{
  padding-bottom: 1rem;
}
.container{
  min-height: calc(100vh - 48px);
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.container-center{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
.block{
  padding: 1rem 0;
}
</style>
