<template>
    <nav>
      <router-link to="/">Go to Home</router-link>
      <router-link to="/auth">Go to Auth</router-link>
      <router-link to="/admin">Go to Admin</router-link>
      <router-link to="/error">Go to Error</router-link>
    </nav>
    <h1>Admin</h1>
    <div class="container">
      <div class="block">
        <router-view />
      </div>
    </div>
</template>

<script setup>
</script>

<style>
</style>